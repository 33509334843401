html {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100vh;
  width: 100vw;
  font-size: 13px;
  background-color: #cdcdcd;
}
body{
  margin: 0;
  padding: 0;
  height:100vh;
  width: 100vw;
}
.bg1{
  margin: 0;
  padding: 0;
  height:100vh;
  width: 100vw;
  background-image: url('bg.jpg');  
  background-position: center;
  background-size:cover;
  position: absolute;
  top:0;
}
.bg2{
  margin: 0;
  padding: 0;
  height:100vh;
  width: 100vw;
  background-image: url('bg.jpg');  
  background-position: center;
  background-size:cover;
  position: absolute;
  top:0;
}
.bg3{
  margin: 0;
  padding: 0;
  height:100vh;
  width: 100vw;
  background-image: url('bg3.jpg');  
  background-position: center;
  background-size:cover;
  position: absolute;
  top:0;
}

.headerbg {
  width: 100%;
  min-height: 55px;
  background-color: #27665a;
}
.cp20{
  padding-top: 20px;
}
.cp50{
  padding-top: 50px;
}

.searchbox {
  border: solid 1px #1a5e4f;
  border-radius: 14px;
  padding: 3px 10px;
  font-weight: bold;
  text-align: right;
  width: 350px;
  font-size: 16px;
}
.searchbox::-webkit-input-placeholder{
  color: #1a5e4f;
}
.searchbox:-moz-placeholder{
  color: #1a5e4f;
}

.txtbox {
  border: solid 1px #1a5e4f;
  border-radius: 10px;
  padding: 5px 15px;
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
  margin-bottom: 10px;
}
.txtbox::-webkit-input-placeholder{
  color: #1a5e4f;
}
.txtbox:-moz-placeholder{
  color: #1a5e4f;
}

.btnlogin {
  border: solid 1px #1a5e4f;
  background-color: #FFF;
  color: #27665a;
  border-radius: 14px;
  padding: 3px 5px;
  width: 110px;
  font-size: 13px;
}
.mydefcolor{
  color: #27665a;
}
.mymenucol{
  border-bottom: #27665a 1px solid;
}
.btnlogin1 {
  border: solid 1px #1a5e4f;
  background-color: #1a5e4f;
  color: white;
  border-radius: 10px;
  padding: 5px 15px;
  width: 250px;
  font-size: 18px;
  margin-top: 10px;
}
.btnreset{
  border: solid 1px #1a5e4f;
  padding: 5px 15px;
  width: 300px;
  color: #1a5e4f;
  margin-bottom: 10px;
}

.txtbold {
  font-size: 12px;
}
.txtbold:hover {
  font-size: 12px;
  font-weight: bold;
}
.mycolor{
  color: #1a5e4f;
  font-size: medium;
}
.tseg {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: #27665a;
}
.spc0 {
  width: 50%;

}

.spc1 {
  width: 50%;
  height: 1px;
  background-color: #27665a;
}
.tseg2 {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: #1a5e4f;
}
.spc01 {
  width: 50%;

}
.spc11 {
  width: 50%;
  height: 1px;
  background-color: #1a5e4f;
}

.submenucontainer{
  z-index: 99;
  position:absolute;
  margin-top: 0px;
  padding-top: 10px;
  margin-left: 0px;
  background-color: rgba(205,205,205, 0.80);
}
.submenucontainer li{
  color: #27665a;
  padding-top: 3px;
  padding-bottom: 3px;
}
.submenuitem{
  font-size: 13px;
  color: #27665a;
  margin-top: 3px;
  margin-bottom: 3px;
}
.submenuitem:hover{
  font-size: 13px;
  color: #27665a;
}
.tseg1 {
  width: 5px;
  height: 5px;
  align-self: center;
  border-radius: 3px;
  background-color: white;
  margin-top: 6px;
}

.carinfobox {
  background-color: #8ca49c;
  border-width: 1px;
  border-style: solid;
  border-color: #234f41;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
  height: 202px;
  padding: 0px;
  margin-top: 30px;
  overflow: hidden;
}
.boxcontainer {
  background-color:rgba(255,255,255);
  padding: 20px;
  border-radius: 10px;
  margin-right: 50px;
  margin-top: 50px;
  margin-left: 50px;
}
.productcontainer{
  background-color:rgba(255,255,255);
}
.savingscroll{
  width: 100%;
  height: 80vh;
  overflow: auto;
}
.aboutcontainer{
  background-color:rgba(255,255,255);
  margin-top: 50px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  border: solid 1px #26624e;
  border-radius: 40px;
}
.shopcontainer{
  background-color:rgba(255,255,255);
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  border: solid 1px #26624e;
  border-radius: 40px;
}
.carboxcontainer{
  width: 100%;
  height: 100%;
  float: left;
  margin-top: 10px;
}
.myselectwidth{
  max-width: 200px;
}
.carbox{
  float: left;
  width: 48%;
  min-width: 320px;
  margin: 4px;
  border: #153933 1px solid;
  border-radius: 10px;
  display: block;
  overflow: hidden;
}
.logincontainer{
  background-color:rgba(255,255,255, 0.39);
  margin-top: 50px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 250px;
  align-self: center;
  border: solid 1px #26624e;
  border-radius: 40px;
  align-content: center;
  justify-content: center;
  text-align: center;
}
.dashcontainer{
  background-color:rgba(21,57,51, 0.10);
  margin-top: 10px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  border: solid 1px #adadad;
  border-radius: 16px;
  color: #153933;
}
.dashcontainer td{
  padding: 5px 10px;
}
.savingcontainer{
  padding: 20px;
  width: 100%;
  height: 600px;
  overflow: auto;
}
.cxm30{
  margin-left: 30px;
  margin-right: 30px;
}
.cxm30 h2 {
  color: #153933;
  font-size:medium;
  font-weight: bold;
  text-transform: uppercase;
}
.cxm30 h3 {
  color: #153933;
  font-size:medium;
  font-weight: bold;
  text-decoration: underline;
}
.cxm30 p, li {
  color: #153933;
  font-size: medium;
}

.productcontainer h2 {
  color: #153933;
  font-size:medium;
  font-weight: bold;
  text-transform: uppercase;
}
.productcontainer h3 {
  color: #153933;
  font-size:medium;
  font-weight: bold;
  text-decoration: underline;
}
.productcontainer p, li {
  color: #153933;
  font-size: medium;
}

.contacttxt{
  color: #27665a;
  width: 300px;  
}
.contacttxt td{
  padding: 0px 8px;
}
.ptab{
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color:rgba(255,255,255, 0.45);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  float: left;
  width: 200px;
}

.ptabactive{
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  vertical-align: middle;
  background-color:rgba(255,255,255);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  float: left;
  width: 200px;
  border-bottom: #27665a 3px solid;
}

.plink{
  color: #27665a;
  display: block;
  height: 60px;
  padding-left: 5px;
  padding-right: 5px;  
}
.plink:hover{
  color: #27665a;
  display: block;
  height: 60px;  
  padding-left: 5px;
  padding-right: 5px;  
}
.b1{
  border: solid 1px #F00;
}
.full{
  display: block;
  width: 100%;
  height: 100%;
}
.rlink{
  z-index: 99;
  margin-left: -60px;
  margin-top: -43px;
  position:absolute;
}
.rlink td{
  vertical-align: middle;
  padding: 0px;
}
.rlink p{
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 10px;
  margin-left: -6px;
  color: #256056;
  text-transform: uppercase;
  font-size: 9px;
  width: 70px;
  text-align: center;
}
.calcbg{
  margin-top: 40px;
  width: 100%;
  color: #27665a;
  font-size:small;
  font-weight: bold;  
}
.btncalc{
  background-color: #27665a;
  border-radius: 10px;
  color: #FFF;
  width: 150px;
}
.calcbg input{
  border-color: #27665a;
  font-size:small;
  font-weight: bold;
  color: #27665a;
  background-color: transparent; 
  text-align: right; 
}
.calcbg select{
  border-color: #27665a;
  font-size: small;
  font-weight: bold;
  color: #27665a;
  background-color: transparent;  
}

.calcbg h2{
  color: #27665a;
  padding: 8px 20px;
}
.calclist{
  border-color: #27665a;
  border-width: 1px;
  margin-top: 15px;
}
.calclist tr{
  border-bottom-color: #27665a;
  border-bottom-width: 1px;
}
.calclist td{
  padding: 3px;
  font-size:small;
}
.calclist thead{
  height: 60px;
  background-color:rgba(255,255,255, 0.39);
}
.txtcenter{
  text-align: center;
}
.activecalc{
  background-color:rgba(255,255,255, 0.39);
  border-radius: 5px;
  border-color: #27665a;
  border-width: 1px;
}
.calcp{
  padding: 10px 16px;
}
.stabactive{
  background-color: #234f41;
  color: #FFF;
  font-size: 14px;
  border-radius: 6px;
  text-align: center;
  border-width: 1px;
  border-color: #FFF;
  padding-top: 5px;
  padding-bottom: 3px;
}
.stab{
  color: #234f41;
  background-color:rgba(21,57,51, 0.20);
  font-size: 14px;
  border-radius: 6px;
  text-align: center;
  border: solid 1px #adadad;
  padding-top: 5px;
  padding-bottom: 3px;
}
.mysavinginfo{
  background-color:rgba(21,57,51, 0.20);
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  border: solid 1px #adadad;
  border-radius: 10px;
  color: #153933;
  margin-top: 10px;
}
.mysavinginfo td{
  font-size: medium;
  font-weight: bold;
  padding: 2px;
}
.savingbtn{
  background-color:rgba(21,57,51, 0.20);
  border: solid 1px #adadad;
  border-radius: 5px;
  padding: 6px 0px;
  width: 250px;
  font-size: medium;
}

.mysavinghuulga{
  background-color:rgba(21,57,51, 0.20);
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 600px;
  overflow: auto;
  border: solid 1px #adadad;
  border-radius: 10px;
  color: #153933;
  margin-top: 10px;
}
.tblhead{
  border-bottom: #1a5e4f 2px solid;
  background-color:rgba(21,57,51, 0.20);
  font-size: large;
  font-weight: bold;
}
.tblrow{
  font-size: larger;
  font-weight: normal;
  padding: 3px 5px;
  border-bottom: #1a5e4f 1px dashed;
}
.tbltitle{
  font-size: larger;
  font-weight: bold;
  padding: 5px;
}
.myvideowidth{
  max-width: 500px;
  width: 500px;
}
.showphone{
  display: none;
}
.cinfo{
  padding-left: 40px;
  padding-top: 15px;
  font-size: small;
}

@media only screen and (max-width: 600px) {
  .mysearch {
    display: none;
  }
  .hidephone{
    display: none;
  }
  .showphone{
    display: block;
  }
  .myvideowidth{
    width: 350px;
  }
  .ptab{
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color:rgba(255,255,255, 0.45);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    float: left;
    width: 100%;
  }
  
  .ptabactive{
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    vertical-align: middle;
    background-color:rgba(255,255,255);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    float: left;
    width: 100%;
  }
  
  .plink{
    color: #27665a;
    display: block;
    height: 40px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .plink:hover{
    color: #27665a;
    display: block;
    height: 40px;  
    padding-left: 5px;
    padding-right: 5px;  
  }
  .tblhead{
    border-bottom: #1a5e4f 2px solid;
    background-color:rgba(21,57,51, 0.20);
    font-size: small;
    font-weight: bold;
  }
  .tblrow{
    font-size:small;
    font-weight: normal;
    padding: 2px 4px;
    border-bottom: #1a5e4f 1px dashed;
  }
  .tbltitle{
    font-size: small;
    font-weight: bold;
    padding: 3px;
  }
  .cinfo{
    padding-left: 20px;
    padding-top: 10px;
    font-size:small;
  }
  .carbox{
    float: left;
    width: 100%;
    min-width: 320px;
    margin: 4px;
    border: #153933 1px solid;
    border-radius: 10px;
    display: block;
    overflow: hidden;
  }
}